(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/rpp/assets/javascripts/rpp.js') >= 0) return;  svs.modules.push('/components/core/rpp/assets/javascripts/rpp.js');
"use strict";


(function (_svs$core$data$rpp, _svs$core$data$rpp2, _svs$core$data$rpp3) {
  if (svs.core.config.data.svsconfig.env !== 'development') {
    return;
  }
  const logger = new svs.core.log.Logger('component:core.jupiterFetch');
  logger.info('Rpp loaded');
  let nw = ((_svs$core$data$rpp = svs.core.data.rpp) === null || _svs$core$data$rpp === void 0 ? void 0 : _svs$core$data$rpp.nw) || 0;
  let rpp = ((_svs$core$data$rpp2 = svs.core.data.rpp) === null || _svs$core$data$rpp2 === void 0 ? void 0 : _svs$core$data$rpp2.rpp) || 0;
  let mrpp = ((_svs$core$data$rpp3 = svs.core.data.rpp) === null || _svs$core$data$rpp3 === void 0 ? void 0 : _svs$core$data$rpp3.mrpp) || 0;
  const iDiv = document.createElement('div');
  iDiv.id = 'jupitercounter';
  iDiv.style.height = '25px';
  iDiv.style.zIndex = '9999';
  iDiv.style.left = '0';
  iDiv.style.padding = '3px';
  iDiv.style.top = '0';
  iDiv.style.position = 'fixed';
  iDiv.style.display = 'none';
  iDiv.style.color = '#fff';
  iDiv.style.backgroundColor = '#000';
  iDiv.style.display = 'block';
  document.getElementsByTagName('body')[0].appendChild(iDiv);
  iDiv.innerHTML = "jp: ".concat(nw, " (").concat(mrpp, ") / ").concat(rpp);
  iDiv.title = 'Network round-trips (multifetch) / Total Jupiter executions';
  svs.events.subscribe('/core/rpp', _ref => {
    let {
      requestCount,
      isMultiFetch
    } = _ref;
    nw++;
    rpp += requestCount;
    if (isMultiFetch) {
      mrpp++;
    }
    iDiv.innerHTML = "jp: ".concat(nw, " (").concat(mrpp, ") / ").concat(rpp);
  });
})();

 })(window);